import React from "react";

const ErrorPage = () => {
    return (
        <div className="container">
            <div className="error-container">
                <div className="error-info">
                    <h1>404</h1>
                    <p>It seems that the page you are looking for no longer exists.<br/>Please contact our  help center or go to the  homepage .</p>
                </div>
                <div className="error-image"></div>
            </div>
        </div>
         
        
    )
}

export default ErrorPage