import React from "react";
import IntentsComponent from "./intents";
import { Grid2 } from "@mui/material";
const HolderIntents = () => {
    let url = new URL(window.location.href);
    let params = new URLSearchParams(url.search.slice(1));
    let obj = {};
    for (let pair of params.entries()) {
        obj[pair[0]] = pair[1];
    }
    const id = obj["id"];
    const type = obj["type"];

    return (
        <>
            <Grid2 container spacing={2}>
            <IntentsComponent id={id} type={type} /> 
            </Grid2>
            
        </>
    )
}

export default HolderIntents