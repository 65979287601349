import React from "react";
 
const PreviewChat = () => {
   
    return (
        <iframe id="myFrame" src="https://virtualassistant.pranathiss.com/virtual" style={{width: "100%", height: "100vh"}} allow="camera; microphone" sandbox="allow-same-origin allow-scripts allow-forms allow-modals allow-popups"></iframe>

    )
}

export default PreviewChat