import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from './../../assets/images/logo@2x.png'
import { useForm } from 'react-hook-form';
import axios from "axios";
const ForgotOTPPage = () => {
    const [loginEmailerror, setLoginEmailerror] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { register, handleSubmit, formState: { errors }, trigger } = useForm({});
    const navigate = useNavigate()
    let url = new URL(window.location.href);
    let params = new URLSearchParams(url.search.slice(1));
    let obj = {};
    for (let pair of params.entries()) {
        obj[pair[0]] = pair[1];
    }

    const email = obj["email"] ? obj["email"] : 0;
    const user_id = obj["userid"] ? obj["userid"] : 0;
    
    const Submit = (data) => {
        setLoginEmailerror("")
        axios.defaults.headers.common = { 'Authorization': `` }
        setLoginEmailerror("")
        setIsSubmitting(true);
        let req = {
            otp: data.otp,
            phone:email,
            user_id:user_id
            
        }

        
        axios.post('/apiV1/email-verify/', req).then(
            (res) => {
                setIsSubmitting(false);
               navigate(`/changepassword?id=${user_id}`)
            }
        ).catch((e) => {
            setIsSubmitting(false);
            if (e.response.data.message) {
                setLoginEmailerror(e.response.data.message)
            }

        });
    }
    return (
        <div className="container" style={{ marginTop: "10%" }}>
            <div className="row justify-content-md-center">
                <div className="col-md-12 col-lg-4">
                    <div className="card login-box-container">
                        <div className="card-body">
                            <div className="authent-logo">
                                <img src={logo} alt="" />
                            </div>
                            <div className="authent-text">
                                <p>Welcome to Chart Agent</p>
                                <p>Please enter your otp</p>
                            </div>

                            <form onSubmit={handleSubmit(Submit)}>
                                <div className="mb-3">
                                    <div className="form-floating">

                                        <input type="text" className="form-control" id="floatingInput" placeholder="name@example.com" {...register("otp", {
                                            required: "This field is required",
                                              
                                        })}
                                            onKeyUp={() => {
                                                trigger("otp");
                                                setLoginEmailerror("");
                                            }} />
                                        <label htmlFor="floatingInput">OTP</label>
                                    </div>
                                    {errors.otp && (<small id='documentPanNumber' className='form-text text-danger'>  {errors.otp.message} </small>)}
                                    {loginEmailerror && (<small id='documentPanNumber' className='form-text text-danger'>  {loginEmailerror} </small>)}
                                </div>
                                 
                                {isSubmitting ?
                                    <div className='text-center'>
                                        <div className='spinner-grow text-primary' role='status'>
                                            <span className='sr-only'>Loading...</span>
                                        </div>
                                    </div> : ""}

                                <div className="d-grid">
                                    <button type="submit" disabled={isSubmitting} className="btn btn-info m-b-xs bluebuttoncustom" >OTP</button>
                                    {/* <button className="btn btn-primary">Facebook</button> */}
                                </div>
                            </form>
                            <div className="authent-reg">
                                <p>Already have an account? <Link to="/Login">Sign In</Link></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    )
}

export default ForgotOTPPage