import React, { useState } from "react";
 
import {
    Box, Button, MenuItem, Select, 
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Paper,
} from '@mui/material';
import axios from "axios";
import { JsonView, allExpanded, defaultStyles } from 'react-json-view-lite';
import 'react-json-view-lite/dist/index.css';

const TestApisAndEndPoints = () => {
    const [apiurl, setapiurl] = useState("")
    const [apitype, setapitype] = useState("GET")
    const [response, setresponse] = useState()
    const [Bearerkey, setBearerkey] = useState()
    const [Bearertoken, setBearertoken] = useState()
    const [rows, setrows] = useState([
        { key: '', value: '', checked: false },

    ]);
    const changeCurrentValue = (data, type, num) => {
        let listarray = rows
        listarray.map((item, i) => {
            if (num == i) {
                if (type == "key") {
                    item.key = data
                }
                if (type == "value") {
                    item.value = data
                }
            }

            return item
        })

        setrows([...listarray])
    }
    const addNewRowtoList = () => {
        let array = rows
        array.push({ key: '', value: '', checked: false },)
        setrows([...array])
    }
    const sendApitoCurlrequest = () => {


        if (apiurl && apitype) {

            axios.defaults.headers.common = { Bearerkey : Bearertoken }
            if (apitype == "GET") {
                axios.get(apiurl).then((rep) => {
                    setresponse(rep.data)
                    console.log(rep.data)
                }).catch((e) => {
                    setresponse(e.response)
                })
            } else if (apitype == "POST" || apitype == "PUT" || apitype == "PATCH" ) {

                let fromdata = new FormData()
                for (let i = 0; i < rows.length; i++) {
                    fromdata.append(rows[i].key, rows[i].value)
                }
                var object = {};
                fromdata.forEach(function (value, key) {
                    object[key] = value;
                });
                
                axios.post(apiurl, object).then((rep) => {
                    setresponse(rep.data)
                }).catch((e) => {
                    setresponse(e.response)
                })
            }else if(apitype == "DELETE"){
                axios.delete(apiurl).then((rep) => {

                    setresponse("Record has been successfully deleted")
                }).catch((e) => {
                    setresponse(e.response)
                })
            }

        } else {
            alert("Please select type and url")
        }
    }
    return (
        <div className="container" style={{ paddingTop: 15 }} >
            <div className="card">
                <div className="card-body">
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 2,
                            padding: 2,

                        }}
                    >
                        {/* Method Dropdown */}
                        <Select
                            defaultValue="GET"
                            sx={{ width: 100, backgroundColor: 'white' }}
                            value={apitype}
                            onChange={(e) => setapitype(e.target.value)}
                        >
                            <MenuItem value="GET">GET</MenuItem>
                            <MenuItem value="POST">POST</MenuItem>
                            <MenuItem value="PUT">PUT</MenuItem>
                            <MenuItem value="PATCH">PATCH</MenuItem>
                            <MenuItem value="DELETE">DELETE</MenuItem>
                        </Select>

                        {/* URL Input */}
                        <TextField
                            placeholder="Enter API URL"
                            variant="outlined"
                            fullWidth
                            value={apiurl}
                            onChange={(e) => setapiurl(e.target.value)}
                            defaultValue=""
                        />

                        {/* Send Button */}
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: '#007bff',
                                color: 'white',
                                textTransform: 'none',
                                height: 50,
                                '&:hover': {
                                    backgroundColor: '#0056b3',
                                },
                            }}
                            onClick={() => sendApitoCurlrequest()}
                        >
                            Send
                        </Button>
                    </Box>
                    <ul className="nav nav-pills mb-3 mx-2" id="pills-tab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className="  active custombutton" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Payload</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="custombutton " id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Headers</button>
                        </li>

                    </ul>
                    <div className="tab-content" id="pills-tabContent">
                        <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                            {apitype == "GET" || apitype == "DELETE" ? "" :

                                <TableContainer component={Paper} sx={{ margin: 'auto', mt: 3 }}>
                                    <Table>
                                        <TableHead>
                                             
                                            <TableRow>
                                                
                                                <TableCell><strong>Key</strong></TableCell>
                                                <TableCell><strong>Value</strong></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        {rows.map((row, index) => (
                                                <TableRow key={index}>
                                                     
                                                <TableCell>
                                                        <TextField
                                                            variant="outlined"
                                                            size="small"
                                                            defaultValue={row.key}
                                                            fullWidth
                                                            value={row.key}
                                                            onChange={(e) => changeCurrentValue(e.target.value, "key", index)}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextField
                                                            variant="outlined"
                                                            size="small"
                                                            defaultValue={row.value}
                                                            fullWidth
                                                            value={row.value}
                                                            onChange={(e) => changeCurrentValue(e.target.value, "value", index)}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                            {/* Add a blank row for user input */}

                                            <TableRow>
                                                <TableCell colSpan={3} sx={{ textAlign: "right" }}><Button variant="contained" onClick={() => addNewRowtoList()} >  Add </Button>
                                                </TableCell>
                                            </TableRow>
 

                                        </TableBody>
                                    </Table>
                                </TableContainer>}
                        </div>
                        <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                        <TableContainer component={Paper} sx={{ margin: 'auto', mt: 3 }}>
                                    <Table>
                                        <TableHead>
                                             
                                            <TableRow>
                                                
                                                <TableCell><strong>Key</strong></TableCell>
                                                <TableCell><strong>Value</strong></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                             
                                                <TableRow  >
                                                     <TableCell>
                                                        <TextField
                                                            variant="outlined"
                                                            size="small"
                                                            defaultValue={"" }
                                                            fullWidth
                                                            value={Bearerkey}
                                                            onChange={(e) => setBearerkey(e.target.value )}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextField
                                                            variant="outlined"
                                                            size="small"
                                                            defaultValue={"" }
                                                            fullWidth
                                                            value={Bearertoken}
                                                            onChange={(e) => setBearertoken(e.target.value)}
                                                        />
                                                    </TableCell>
                                                   
                                                </TableRow>
                                            
 

                                        </TableBody>
                                    </Table>
                                </TableContainer>             
                        </div>

                    </div>



                    <div className="card">
                        <div className="card-body">
                            {response ? <JsonView data={response} shouldExpandNode={allExpanded} style={defaultStyles} /> : ""}

                        </div>
                    </div>
                </div>
            </div>
        </div>


    )
}

export default TestApisAndEndPoints