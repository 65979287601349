import React from "react";
 
import profileimage from './../assets/images/profile-bg.png'
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Link, useNavigate, useLocation } from "react-router-dom";
const TopHeader = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const handleClose = () => {
        sessionStorage.removeItem("Admintoken")

        sessionStorage.removeItem("user-info")

        setAnchorEl(null);
        navigate("/login")
    }
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose2 = () => {
        navigate("/oldpasswordchange")
    }
    return (
        <>


            <div className="page-header inmobilehide">
                <nav className="navbar navbar-expand-lg d-flex justify-content-between ">
                    <div className="" id="navbarNav">
                        <Link className="navbar-brand" to="/"></Link>

                    </div>
                    <div className="logo">
                        <div className="" id="navbarNav">
                            <ul className="navbar-nav" id="leftNav">
                                 
                                <li className="nav-item">
                                    <Link to="/"> Dashboard</Link>

                                </li>
                                <li className={location.pathname === "/" ? "nav-item " : "nav-item"}  >
                                    <Link to="/" className={location.pathname === "/" ? "bluebuttoncustom2 " : " "}   > AI Chat Agent</Link>

                                </li>
                                <li className="nav-item">
                                    <Link to="/"> Usage and Plan</Link>

                                </li>
                                <li className="nav-item">
                                    <Link to="/"> Help Support</Link>

                                </li>

                                {/* <li className="nav-item">
                            <Link to="/" >Settings</Link>
                             
                        </li>
                        <li className="nav-item">
                        <Link to="/" >Help</Link>
                             
                        </li> */}
                            </ul>
                        </div>
                    </div>
                    <div className="" id="headerNav">
                        {/* <Button   className="PreviewChartbot"  variant="contained" size="large"  >Preview Chartbot </Button>
                        */}
                        <div>
                            <Button
                                id="basic-button"
                                aria-controls={open ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={handleClick}
                            >
                                <img src={profileimage} alt="" className="profilelogo" />
                            </Button>
                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                            >
                                <MenuItem onClick={handleClose}>Profile</MenuItem>
                                <MenuItem onClick={handleClose2}>Change Password</MenuItem>
                                <MenuItem onClick={handleClose}>Logout</MenuItem>
                            </Menu>
                        </div>

                    </div>
                </nav>
            </div>
            <div className="mobile-nav">
                <ul className="navbar-nav" id="leftNav">
                     
                    <li className="nav-item">
                        <Link to="/"> Dashboard</Link>

                    </li>
                    <li className={location.pathname === "/" ? "nav-item active" : "nav-item"}  >
                        <Link to="/" > AI Chat Agent</Link>

                    </li>
                    <li className="nav-item">
                        <Link to="/"> Usage and Plan</Link>

                    </li>
                    <li className="nav-item">
                        <Link to="/"> Help Support</Link>

                    </li>

                    {/* <li className="nav-item">
                            <Link to="/" >Settings</Link>
                             
                        </li>
                        <li className="nav-item">
                        <Link to="/" >Help</Link>
                             
                        </li> */}
                </ul>
            </div>

        </>
    )
}

export default TopHeader