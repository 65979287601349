import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from './../../assets/images/logo@2x.png'
import { useForm } from 'react-hook-form';
import axios from "axios";
const Changepassword = () => {
    const [loginEmailerror, setLoginEmailerror] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { register, handleSubmit, formState: { errors }, trigger  } = useForm({});
    const navigate = useNavigate()
    // const login = () => {
    //     sessionStorage.setItem('Admintoken', "welcome")
    //     navigate("/")
    // }
    let url = new URL(window.location.href);
    let params = new URLSearchParams(url.search.slice(1));
    let obj = {};
    for (let pair of params.entries()) {
        obj[pair[0]] = pair[1];
    }

    const user_id = obj["id"] ? obj["id"] : 0;

    const Submit = (data) => {
        setLoginEmailerror("")
        axios.defaults.headers.common = { 'Authorization': `` }
        setLoginEmailerror("")
        setIsSubmitting(true);
        let req = {
            password: data.Password,
            password2: data.confirmPassword
        }
 
        axios.patch(`/apiV1/change-passwordv2/${user_id}/`, req).then(
            (res) => {
                setIsSubmitting(false);
                navigate("/changepasswordsuccess")
            }
        ).catch((e) => {
            setIsSubmitting(false);
            if (e.response.data.detail) {
                
            }

        });
    }
    return (
        <div className="container" style={{ marginTop: "10%" }}>
            <div className="row justify-content-md-center">
                <div className="col-md-12 col-lg-4">
                    <div className="card login-box-container">
                        <div className="card-body">
                            <div className="authent-logo">
                                <img src={logo} alt="" />
                            </div>
                            <div className="authent-text">
                                <p>Welcome to Chart Agent</p>
                                <p>Please Sign-in to your account.</p>
                            </div>

                            <form onSubmit={handleSubmit(Submit)}>
                                 
                                <div className="mb-3">
                                    <div className="form-floating">
                                        <input type="password" className="form-control" id="floatingPassword" placeholder="Password" {...register("Password", {
                                            required: "This field is required",
                                            pattern: {
                                                value:
                                                    /^(?!.*\s)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).{8,16}$/,
                                                message: "Password must be 8-16 characters, with at least one digit, one lowercase, and one uppercase letter.",
                                            },
                                        })}
                                            onKeyUp={() => {
                                                trigger("Password");
                                                setLoginEmailerror("");
                                            }} />
                                        <label for="floatingPassword">Password</label>
                                    </div>
                                    {errors.Password && (<small id='documentPanNumber' className='form-text text-danger'>  {errors.Password.message} </small>)}
                                   

                                </div>
                                <div className="mb-3">
                                    <div className="form-floating">
                                        <input type="password" className="form-control" id="confirmPassword" placeholder="Password" {...register("confirmPassword", {
                                            required: "This field is required",
                                            pattern: {
                                                value:
                                                    /^(?!.*\s)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).{8,16}$/,
                                                message: "Password must be 8-16 characters, with at least one digit, one lowercase, and one uppercase letter.",
                                            },
                                        })}
                                            onKeyUp={() => {
                                                trigger("confirmPassword");
                                                setLoginEmailerror("");
                                            }} />
                                        <label for="confirmPassword">Confirm Password</label>
                                    </div>
                                    {errors.confirmPassword && (<small id='documentPanNumber' className='form-text text-danger'>  {errors.confirmPassword.message} </small>)}
                                    {loginEmailerror && (<small id='documentPanNumber' className='form-text text-danger'>  {loginEmailerror} </small>)}

                                </div>
                                  
                                {isSubmitting ?
                                    <div className='text-center'>
                                        <div className='spinner-grow text-primary' role='status'>
                                            <span className='sr-only'>Loading...</span>
                                        </div>
                                    </div> : ""}

                                <div className="d-grid">
                                    <button type="submit" disabled={isSubmitting} className="btn btn-info m-b-xs bluebuttoncustom" >Change Password</button>
                                    {/* <button className="btn btn-primary">Facebook</button> */}
                                </div>
                            </form>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>


    )
}

export default Changepassword