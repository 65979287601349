import { Navigate } from "react-router-dom";
import TopHeader from "./Components/TopHeader";
export default function PrivateRoute({ element }) {
    return sessionStorage.getItem("Admintoken") ? (
        <div className="page-container">
            <TopHeader />
            <div className="page-content">
                {element} 
            </div>
        </div>

    ) : (
        <Navigate to="/login" />
    );
}

