import { Alert, Box, Button, Grid2, Modal, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";

import boaticon from './../assets/images/boticon.png'
import basicbot from './../assets/images/basicbot.webp'
import advanced from './../assets/images/advanced.webp'
import advanced2 from './../assets/images/basicbot2.png'
import advanced3 from './../assets/images/basicbot3.png'
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',

    boxShadow: 24,
    p: 4,
};



const Dashboard = () => {
    const navigate = useNavigate()
    const [open, setopen] = useState(false)
    const [deleteconfim, setdeleteconfim] = useState(false)
    const [type, settype] = useState()
    const [type2, settype2] = useState()

    const [chartboatname, setchartboatname] = useState("")
    const [listofchartboats, setlistofchartboats] = useState([])
    const [editID, seteditID] = useState()
    const [deleteID, setdeleteID] = useState()
    const [errormsg, seterrormsg] = useState()
  
    const [typesofcharts, settypesofcharts] = useState([])
    const handleOpen = () => {
        setopen(true)
        seteditID("")
        settype("")
        settype2("")
        setchartboatname("")
        seterrormsg("")
    }
    const handleClose = () => {
        setopen(false)
    }
    const handleClose2 = (data) => {
        //settype(data);
        settype2(data);
        // setopen(false)
    }


    const createchartbot = () => {
        let object = {
            name: chartboatname,
            type: type2
        }
        axios.defaults.headers.common = { 'Authorization': `Bearer ${sessionStorage.getItem("Admintoken")}` }
        if (chartboatname && type2) {
            if (editID) {
                axios.patch(`apiV1/chatbot/${editID}/`, object).then((rep) => {
                    settype(type2)
                    handleClose();

                    //settypesofcharts(rep.data)
                }).catch((e) => {
                    if (e.response.data.non_field_errors) {
                        seterrormsg(e.response.data.non_field_errors[0])
                    }
                })
            } else {
                axios.post('apiV1/chatbot/', object).then((rep) => {
                    settype(type2)
                    handleClose();
                    //settypesofcharts(rep.data)
                    console.log(rep.data)
                    navigate(`/chartbotintent?id=${rep.data.id}&type=${rep.data.type}`)
                }).catch((e) => {
                    if (e.response.data.non_field_errors) {
                        seterrormsg(e.response.data.non_field_errors[0])
                    }

                })
            }

        } else {
            seterrormsg("Please enter chart bot name and type")
        }
    }


    const GetModulesList = () => {
        axios.defaults.headers.common = { 'Authorization': `Bearer ${sessionStorage.getItem("Admintoken")}` }
        axios.get('apiV1/chatbot/choices/').then((rep) => {
            settypesofcharts(rep.data)
        }).catch((e) => {

        })
    }
    const GetChartboatsList = () => {
        axios.defaults.headers.common = { 'Authorization': `Bearer ${sessionStorage.getItem("Admintoken")}` }
        axios.get('apiV1/chatbot/').then((rep) => {
            setlistofchartboats(rep.data)
        }).catch((e) => {

        })
    }
    const deletechartboatwithid = () => {
        axios.defaults.headers.common = { 'Authorization': `Bearer ${sessionStorage.getItem("Admintoken")}` }
        axios.delete(`apiV1/chatbot/${deleteID}/`).then((rep) => {
            GetChartboatsList();
            setdeleteID("")
            setdeleteconfim(false)
        }).catch((e) => {

        })
    }
    const oneditchartboat = (row) => {
        handleOpen()
        setchartboatname(row.name)
        settype(row.type)
        settype2(row.type)
        seteditID(row.id)
    }
    useEffect(() => {
        GetModulesList()
        GetChartboatsList()
    }, [])
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open3 = Boolean(anchorEl);
 
    const ITEM_HEIGHT = 48;
    const handleClose12 = () => {
        
            let userslists = listofchartboats;
            userslists.map((item, i )=>{
                item.active = null
                return item;
            })
            setlistofchartboats([...userslists])
        
    };
    const handleClickthree = (event, id) => {
        let userslists = listofchartboats;
        userslists.map((item, i )=>{
            if(id == item.id){
                item.active = event.currentTarget
            }else{
                item.active = null
            }
            return item;
        })
        console.log(userslists) 
        setlistofchartboats([...userslists])
        
    };
    const redirecttourl = (url) => {
        navigate(url)
    }
    return (
        <div className="" style={{paddingTop:"30px"}}>
            <Box sx={{textAlign:"right", marginBottom:"15px"}} >
            <Button variant="contained" size="large" onClick={handleOpen} className="bluebuttoncustom2"><img src={boaticon} width={30} className="me-2" alt="logo"/> Create Chat Agent </Button>

            </Box>
             

        <Grid2 container spacing={2}  >
            
            {listofchartboats.map((row, i) => (
            <Grid2 size={{xs:6,md:4, lg:3}} >
                <div className="card" key={i}>
                    <div className=" " >
                        <div className="post text-center">
                               
                            <div className="post-header">
                           
                                <div className="post-header-actions">
                                    <div>
                                        <IconButton
                                            aria-label="more"
                                            id="long-button"
                                            aria-controls={open3 ? 'long-menu' : undefined}
                                            aria-expanded={open3 ? 'true' : undefined}
                                            aria-haspopup="true"
                                            onClick={(e)=> handleClickthree(e, row.id)}
                                        >
                                            <MoreVertIcon />
                                        </IconButton>
                                        <Menu
                                            id="long-menu"
                                            MenuListProps={{
                                                'aria-labelledby': 'long-button',
                                            }}
                                            anchorEl={row.active}
                                            open={row.active}
                                            onClose={handleClose12}
                                            slotProps={{
                                                paper: {
                                                    style: {
                                                        maxHeight: ITEM_HEIGHT * 4.5,
                                                        width: '20ch',
                                                    },
                                                },
                                            }}
                                        >
                                             <MenuItem   onClick={() => { setdeleteconfim(true); setdeleteID(row.id) }}> Delete</MenuItem>
                                             <MenuItem  onClick={() => oneditchartboat(row)}> Edit</MenuItem>
                                        </Menu>
                                    </div>
                                  
                                </div>
                            </div>
                            <div className="d-block text-center cursor-pointer" onClick={()=> redirecttourl(`/chartbotintent?id=${row.id}&type=${row.type}`)}>
                                        <img src={row.type == "BASIC_WITHOUT_KB" ? basicbot  : row.type == "ADVANCED_WITH_KB" ? advanced : ""  } alt="bot" width={100}  className="borderradius" />
                                    </div>
                            <div className="post-info" >
                            {/* component={Link} to={`/chartbotintent?id=${row.id}&type=${row.type}`} */}
                                    <Link className="post-author" to={`/chartbotintent?id=${row.id}&type=${row.type}`}>{row.name}</Link> <br/>
                                    <span className="post-date">{row.type == "BASIC_WITHOUT_KB" ? "BASIC WITHOUT KB" : row.type == "ADVANCED_WITH_KB" ? "ADVANCED WITH KB" : ""  }</span>
                                </div>
                             <br/>
                        </div>
                    </div>
                </div>
            </Grid2>
            ))}
            

            <Modal open={open} onClose={handleClose} aria-labelledby="parent-modal-title" aria-describedby="parent-modal-description" >
                <Box sx={{ ...style, width: 500 }}>
                    <Box textAlign={"center"} sx={{paddingBottom:"45px"}}> 
                        <img src={boaticon} alt="logo" width={100}/>
                    </Box>

                    <Grid2 container spacing={2} textAlign={"center"}>
                        <Grid2 size={12}>
                            <TextField
                                required
                                id="outlined-required"
                                label="Chart Agent Name"
                                fullWidth
                                sx={{ mb: 3 }}
                                value={chartboatname}
                                defaultValue=""
                                onChange={(e) => { setchartboatname(e.target.value); seterrormsg("") }}

                            />

                        </Grid2>
                    </Grid2>
                    <Grid2 container spacing={2} textAlign={"center"}>
                        {typesofcharts.map((charts, i) => {
                            return (
                                <Grid2 size={6} key={i+150}>

                                    <div className={type2 == charts ? "borderwithstyle active" : "borderwithstyle"} onClick={() => { handleClose2(charts); seterrormsg("") }}>
                                        <div className=""><img src={charts == "BASIC_WITHOUT_KB" ? advanced2 : charts == "ADVANCED_WITH_KB" ? advanced3 : ""} width={50} height={80}/> <br/></div>
                                        <div className="fontandpadding">{charts == "BASIC_WITHOUT_KB" ? "Basic without  knowledge base" : charts == "ADVANCED_WITH_KB" ? "Advance with knowledge base" : ""}</div>
                                    </div>
                                </Grid2>
                            )
                        })}


                    </Grid2>

                    {errormsg && <Alert severity="error" sx={{ mt: 2 }}>{errormsg}</Alert>}
                    <Grid2 container spacing={2} textAlign={"center"}>
                        <Grid2 size={12} sx={{ mt: 4 }} textAlign={"center"}>

                            <Button variant="contained" sx={{ mx: 2 }} onClick={() => createchartbot()}>{editID ? "Update Chart Agent" : "Create Chart Agent"}</Button>
                            <Button variant="contained" color="error" onClick={() => {handleClose(); handleClose12()}}>Cancel</Button>
                        </Grid2>
                    </Grid2>
                </Box>
            </Modal>


            <Modal open={deleteconfim} onClose={() => setdeleteconfim(false)} aria-labelledby="parent-modal-title" aria-describedby="parent-modal-description" >
                <Box sx={{ ...style, width: 500 }}>
                    <Grid2 container spacing={2} textAlign={"center"}>
                        <Grid2 size={12}>
                            Are you sure you want to delete this Chart Agent?
                        </Grid2>
                    </Grid2>

                    <Grid2 container spacing={2} textAlign={"center"}>
                        <Grid2 size={12} sx={{ mt: 4 }} textAlign={"center"}>
                            <Button variant="contained" sx={{ mx: 2 }} color="error" onClick={() => deletechartboatwithid()}>Delete</Button>
                            <Button variant="contained" onClick={() => {setdeleteconfim(false); handleClose12()}}>Cancel</Button>
                        </Grid2>
                    </Grid2>
                </Box>
            </Modal>
        </Grid2>
        </div>
    )
}

export default Dashboard