import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from './../../assets/images/logo@2x.png'
import { useForm } from 'react-hook-form';
import axios from "axios";
const ForgotPage = () => {
    const [loginEmailerror, setLoginEmailerror] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { register, handleSubmit, formState: { errors }, trigger  } = useForm({});
    const navigate = useNavigate()
    // const login = () => {
    //     sessionStorage.setItem('Admintoken', "welcome")
    //     navigate("/")
    // }
    const Submit = (data) => {
        setLoginEmailerror("")
        axios.defaults.headers.common = { 'Authorization': `` }
        setLoginEmailerror("")
        setIsSubmitting(true);
        let req = {
            email: data.Email,
            
        }

        
        axios.post('/apiV1/forgot-password/', req).then(
            (res) => {
                setIsSubmitting(false);
               navigate(`/forgotpassword-otp?email=${res.data.phone}&userid=${res.data.user_id}`)
            }
        ).catch((e) => {
            setIsSubmitting(false);
            if (e.response.data.message) {
                setLoginEmailerror(e.response.data.message)
            }

        });
    }
    return (
        <div className="container" style={{ marginTop: "10%" }}>
            <div className="row justify-content-md-center">
                <div className="col-md-12 col-lg-4">
                    <div className="card login-box-container">
                        <div className="card-body">
                            <div className="authent-logo">
                                <img src={logo} alt="" />
                            </div>
                            <div className="authent-text">
                                <p>Welcome to Chart Agent</p>
                                <p>Please enter you forgot email</p>
                            </div>

                            <form onSubmit={handleSubmit(Submit)}>
                                <div className="mb-3">
                                    <div className="form-floating">

                                        <input type="email" className="form-control" id="floatingInput" placeholder="name@example.com" {...register("Email", {
                                            required: "This field is required",
                                            pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]{2,}\.+[A-Z]{2,5}$/i,
                                                message: "Please enter a valid Email ID",
                                            },
                                            // minLength: {
                                            //   value: 3,
                                            //   message: "Please enter min 3 characters ",
                                            // },
                                        })}
                                            onKeyUp={() => {
                                                trigger("Email");
                                                setLoginEmailerror("");
                                            }} />
                                        <label htmlFor="floatingInput">Email address</label>
                                    </div>
                                    {errors.Email && (<small id='documentPanNumber' className='form-text text-danger'>  {errors.Email.message} </small>)}
                                    {loginEmailerror && (<small id='documentPanNumber' className='form-text text-danger'>  {loginEmailerror} </small>)}
                                </div>
                                 
                                {isSubmitting ?
                                    <div className='text-center'>
                                        <div className='spinner-grow text-primary' role='status'>
                                            <span className='sr-only'>Loading...</span>
                                        </div>
                                    </div> : ""}

                                <div className="d-grid">
                                    <button type="submit" disabled={isSubmitting} className="btn btn-info m-b-xs bluebuttoncustom" >Forgot Password</button>
                                    {/* <button className="btn btn-primary">Facebook</button> */}
                                </div>
                            </form>
                            <div className="authent-reg">
                                <p>Already have an account? <Link to="/Login">Sign In</Link></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    )
}

export default ForgotPage