import React from "react";
import { Link } from "react-router-dom";
import logo from './../../assets/images/logo@2x.png'
 
const AccountSuccess = () => {
   
    return (
        <div className="container" style={{ marginTop: "10%" }}>
            <div className="row justify-content-md-center">
                <div className="col-md-12 col-lg-4">
                    <div className="card login-box-container">
                        <div className="card-body">
                            <div className="authent-logo">
                                <img src={logo} alt="" />
                            </div>
                            <div className="authent-text">
                                <p>Welcome to Chart Agent</p>
                                <p>Chart Agent account create</p>
                            </div>
                            <div  className="authent-reg">
                                Successfully Account Created <br/>
                                <Link to="/Login">Sign In</Link>
                            </div>
                            
                         
                        </div>
                    </div>
                </div>
            </div>
        </div>


    )
}

export default AccountSuccess